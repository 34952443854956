import axios from 'axios';
import { apiUrl } from '../config';

const endpoint = `${apiUrl}/auth`;

export default {
  async signIn(username, password) {
    const response = await axios.post(`${endpoint}/sign_in`, { username, password });
    if (response.data.success)
      return response.data;
    throw new Error(response.data.message);
  },

  async refresh(refreshToken) {
    return axios.post(`${endpoint}/refresh`, { refreshToken });
  },

  async signOut(refreshToken) {
    return axios.post(`${endpoint}/sign_out`, { refreshToken });
  },
};
