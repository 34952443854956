import axios from 'axios';
import { apiUrl } from '../config';

const endpoint = `${apiUrl}/categories`;

export default {
  async getEntities(options) {
    const result = await axios.post(endpoint, options);
    return result.data.entities;
  },
};
