import axios from 'axios';
import authApi from '../api/auth';

import logger from '@/dev/logger';

logger.init('store/auth');

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

// used as reference: https://gist.github.com/isumix/28daba0671348c169b88d5176eab5e99

export default {
  namespaced: true,
  state: () => ({
    accessToken: null,
    refreshToken: null,
  }),
  getters: {
    isAuthenticated(state) {
      console.log(`getters.isAuthenticated ${!!state.accessToken}`);
      return !!state.accessToken; // ! проверять экспирацию
    },
  },
  mutations: {
    saveAccessToken: (state, token) => (state.accessToken = token),
    saveRefreshToken: (state, token) => (state.refreshToken = token),
  },
  actions: {
    // Восстанавливаем сессию в main.js (1 раз после открытия браузера или F5)
    restoreSession({ commit }) {
      logger.group('restoreSession');
      const auth = localStorage.getItem('auth');
      logger.info('auth: ', auth);
      if (auth) {
        const { accessToken, refreshToken } = JSON.parse(auth);
        logger.info({accessToken, refreshToken});
        axios.defaults.headers.common.Authorization = accessToken;
        commit('saveAccessToken', accessToken);
        commit('saveRefreshToken', refreshToken);
      }
      logger.groupEnd();
    },
    saveSession({ commit }, { accessToken, refreshToken }) {
      logger.info('saveSession', {accessToken, refreshToken});
      axios.defaults.headers.common.Authorization = accessToken;
      commit('saveAccessToken', accessToken);
      commit('saveRefreshToken', refreshToken);
      localStorage.setItem('auth', JSON.stringify({ accessToken, refreshToken }));
    },
    clearSession({ commit }) {
      logger.info('clearSession');
      delete axios.defaults.headers.common.Authorization;
      commit('saveAccessToken', null);
      commit('saveRefreshToken', null);
      localStorage.removeItem('auth');
    },
    async proceedSignInData({ commit, dispatch }, { refreshToken, accessToken }) {
      logger.info('proceedSignInData', refreshToken, accessToken);
      if (!refreshToken)
        throw Error('Trying to proceed sign in data without actual data');
      dispatch('saveSession', { refreshToken, accessToken });
      // this.$store.state.user_data.user_id = response.user_id;
      // TODO: separate
      commit('profile/clearUser', {}, { root: true });
      const userId = parseJwt(refreshToken).sub;
      commit('profile/saveUserId', userId, { root: true });
      await dispatch('profile/getInfo', {}, { root: true });
      dispatch('profile/saveProfile', {}, { root: true });
      // this.redirect_auth(this.$store.getters.getLastFrom); // !!!
    },
    async signIn({ dispatch }, { username, password }) {
      const { accessToken, refreshToken } = await authApi.signIn(username, password);
      dispatch('proceedSignInData', { accessToken, refreshToken });
    },
    async signOut({ dispatch, commit, state }) {
      await authApi.signOut(state.refreshToken);
      dispatch('clearSession');
      commit('profile/clearUser', null, {root: true});
      dispatch('profile/saveProfile', null, {root: true});
      return true;
    },
    async refreshToken({ dispatch, state }) {
      const { refreshToken } = state;
      if (!refreshToken)
        return false;
      const response = await authApi.refresh(refreshToken);
      if (response.data.success) {
        const { accessToken } = response.data;
        console.log(accessToken);
        dispatch('saveSession', { accessToken, refreshToken });
        return true;
      }
    },
  },
};
