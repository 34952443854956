import 'intersection-observer/intersection-observer';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import axios from 'axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './scss/styles.scss';
import * as config from './config';
import bus from './bus';
import { responseHandler, requestHandler } from './network_handlers';

axios.interceptors.request.use(requestHandler);
axios.interceptors.response.use(responseHandler);

store.dispatch('auth/restoreSession');
store.dispatch('profile/restoreProfile');
store.dispatch('profile/getFavorites');

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
const app = createApp(App);
// event bus для случаев, когда использование Vuex неоправдано
app.config.globalProperties.$bus = bus;
app.config.globalProperties.$config = config;
app.use(pinia).use(store).use(router).mount('#app');
