import axios from 'axios';
import store from '@/store';
import router from '@/router';

export const requestHandler = request => {
  request.withCredentials = true;
  return request;
};
export const responseHandler = async response => {
  if (!response.data.success) {
    // обработать ошибки по коду в response.data.error, вывести текст response.data.message
    switch (response.data.error) {
      case 'REFRESH_TOKEN_INVALID':
        store.dispatch('auth/signOut');
      case 'INVALID_TOKEN':
        console.log('INVALID_TOKEN');
      case 'TOKEN_EXPIRED':
        delete response.config.headers['Authorization'];
        if (!await store.dispatch('auth/refreshToken'))
          router.push({ name: 'auth' });
        else
          return axios.request(response.config);
      case 'NO_TOKEN':
        console.error('NO TOKEN');
    }
  }
  return response;
};

export const errorHandler = error => {
  const status = error.response ? error.response.status : null;
  if (status === 401) {
    return Promise.request(error.config);
  }

  return Promise.reject(error);
};
