/* eslint-disable max-len */
export const env = process.env.NODE_ENV;
export const appVersion = process.env.VUE_APP_VERSION;
export const apiUrl = process.env.VUE_APP_API_URL;
export const siteUrl = process.env.VUE_APP_SITE_URL;
export const imagesUrl = process.env.VUE_APP_IMAGES_URL;
export const imagesTmpUrl = process.env.VUE_APP_IMAGES_TMP_URL;
export const oAuth = {
  vk: {
    url: `https://oauth.vk.com/authorize?client_id=7131724&display=popup&redirect_uri=${siteUrl}/oauth/mobile/vkontakte&response_type=code&state=v2`,
  },
  ok: {
    url: `https://connect.ok.ru/oauth/authorize?client_id=512000025931&scope=GET_EMAIL;PHOTO_CONTENT;VALUABLE_ACCESS;LONG_ACCESS_TOKEN&response_type=code&redirect_uri=${siteUrl}/oauth/mobile/odnoklassniki&state=v2`,
  },
  fb: {
    url: `https://www.facebook.com/v4.0/dialog/oauth?client_id=846677019067129&redirect_uri=${siteUrl}/oauth/mobile/facebook&state=temporary_state&scope=email&state=v2`,
  },
  ap: {
    url: `https://abkhaz-auto.ru/oauth/proxy/apple?version=v2`,
  },
};
