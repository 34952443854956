import { createStore } from 'vuex';
import router from '@/router';
import auth from './auth';
import loading from './loading';
import profile from './profile';

export default createStore({
  modules: {
    auth,
    loading,
    profile,
  },
  state() {
    return {
      appVersion: process.env.VUE_APP_VERSION || '0',
      from: null,
    };
  },
  mutations: {},
  actions: {
    redirectAfterSignIn({ state }) {
      console.error('state.from', state.from);
      if (state.from)
        router.replace(state.from);
      else
        router.replace({ name: 'profile' });
    },
  },
});
