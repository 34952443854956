import { defineStore } from 'pinia';
import categoriesApi from '@/api/categories';
import { now } from '@/api/utils';

export const usePostsCache = defineStore({
  id: 'posts',
  state: () => ({
    posts: {},
  }),
  actions: {
    put(posts) {
      posts.forEach(post => {
        this.posts[post.id] = post;
      });
    },
  },
});

export const useCategoriesCache = defineStore({
  id: 'categories',
  persist: true,
  state: () => ({
    categories: {
      188: { id: 188, title: 'Авто', class: 'auto' },
      1: { id: 1, title: 'Барахолка', class: 'all' },
      82: { id: 82, title: 'Суточно-Тут', class: 'hotel' },
      9: { id: 9, title: 'Недвижимость', class: 'estate' },
      190: { id: 190, title: 'Автозапчасти', class: 'autoparts' },
      // News
      // Comments
      189: { id: 189, title: 'Авто под заказ', class: 'commercial' },
      8: { id: 8, title: 'Работа', class: 'work' },
      4: { id: 4, title: 'Услуги', class: 'service' },
      12: { id: 12, title: 'Одежда и обувь', class: 'wear' },
      15: { id: 15, title: 'Электроника', class: 'electro' },
      7: { id: 7, title: 'Шины', class: 'wheels' },
      5: { id: 5, title: 'Диски', class: 'disks' },
      14: { id: 14, title: 'Гаражи', class: 'garage' },
      6: { id: 6, title: 'Животные', class: 'animal' },
      3: { id: 3, title: 'Оборудование', class: 'equipment' },
      11: { id: 11, title: 'Мебель', class: 'furniture' },
      2: { id: 2, title: 'Компьютеры', class: 'pc' },
      16: { id: 16, title: 'Все для дома', class: 'home' },
      13: { id: 13, title: 'Хобби', class: 'hobby' },
      10: { id: 10, title: 'Разное', class: 'other' },
    },
    updated_at: null,
  }),
  actions: {
    // not used
    put(categories) {
      categories.forEach(category => {
        this.categories[category.id] = category;
      });
    },
    async update() {
      const categories_options = {};
      for (const [id, data] of Object.entries(this.categories)) {
        const viewed_at = data.viewed_at || 0;
        categories_options[id] = { viewed_at };
      }
      const result = await categoriesApi.getEntities({ categories_options });
      result.forEach(data => {
        this.categories[data.id]['newPostsCount'] = data.new_posts_count;
      });
      this.updated_at = now();
    },
    requestUpdate() {
      if (this.updated_at === null || now() - this.updated_at > 3 * 60)
        this.update();
    },
    viewed(category_id) {
      if (category_id in this.categories) {
        this.categories[category_id].viewed_at = now();
        this.categories[category_id].newPostsCount = 0;
      }
    },
  },
});
