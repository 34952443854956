const userAgent = navigator.userAgent || navigator.vendor || window.DOMPointReadOnly;

export const randomInt = (min, max) => Math.floor(min + Math.random() * (max - min + 1));
export const isMobile = () => /windows phone|android|iPad|iPhone|iPod/.test(userAgent);
export const plural = (n, f1, f2, f5) => (n % 10 === 1 && n % 100 !== 11) ? f1 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? f2 : f5;

export default {
  randomInt,
  plural,
};

export const equal = (o1, o2) => {
  const k1 = Object.keys(o1);
  const k2 = Object.keys(o2);

  if (k1.length !== k2.length)
    return false;

  for (let key of k1) {
    console.log('key', key);
    if (o1[key] !== o2[key]) {
      return false;
    }
  }
  return true;
}

export const priceStr = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
export const now = () => Math.floor(Date.now() / 1000);
