import profileApi from '../api/profile';
import favoritesApi from '../api/favorites';

import logger from '@/dev/logger';

logger.init('store/profile');

const GUEST_DISPLAYNAME = 'Гость';

export default {
  namespaced: true,
  state: () => ({
    userId: null,
    displayName: GUEST_DISPLAYNAME,
    balance: 0,
    isAdmin: false,
    favorites: [],
  }),
  mutations: {
    clearUser(state) {
      logger.info('profile/clearUser');
      state.userId = null;
      state.displayName = GUEST_DISPLAYNAME;
      state.balance = 0;
      state.isAdmin = false;
    },
    saveUserId(state, userId) {
      state.userId = userId;
    },
    saveUserInfo(state, userInfo) {
      logger.info('profile/saveUserInfo', userInfo);
      state.displayName = userInfo.displayName;
      state.balance = userInfo.balance;
      state.isAdmin = userInfo.isAdmin;
    },
    saveFavorites(state, favorites) {
      state.favorites.length = 0;
      state.favorites.push(...favorites);
    },
    addFavorite(state, postId) {
      if (state.favorites.indexOf(postId) === -1)
        state.favorites.push(postId);
    },
    removeFavorite(state, postId) {
      const index = state.favorites.indexOf(postId);
      if (index !== -1)
        state.favorites.splice(index, 1);
    },
  },
  actions: {
    restoreProfile({ commit }) {
      logger.info('profile/restoreProfile');
      const profile = localStorage.getItem('profile');
      if (profile) {
        const { userId, displayName, balance, isAdmin } = JSON.parse(profile);
        logger.info('profile/restoreProfile got info', {userId, displayName, balance, isAdmin});
        commit('saveUserId', userId);
        commit('saveUserInfo', { displayName, balance, isAdmin});
      }
    },
    saveProfile({ state }) {
      const { userId, displayName, balance, isAdmin } = state;
      logger.info('profile/saveProfile', {userId, displayName, balance, isAdmin});
      localStorage.setItem('profile', JSON.stringify({userId, displayName, balance, isAdmin}));
    },
    async getInfo({ commit }) {
      const userInfo = await profileApi.getInfo();
      commit('saveUserInfo', userInfo.info);
    },
    async getFavorites({ commit }) {
      const favorites = await favoritesApi.getList();
      commit('saveFavorites', favorites);
    },
  },
};
