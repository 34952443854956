<template>
  <div class="notifications">
    <div v-for="notification in notifications" :class="notification.type">
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import { isString } from 'lodash';

export default {
  data() {
    return {
      id: 0,
      notifications: [],
    };
  },
  beforeCreate() {
    this.$bus.on('popup:message', notification => isString(notification) ? this.notifications.push({ message: notification}) : this.notifications.push(notification));
  },
  watch: {
    notifications: {
      handler(val) {
        if (val.length === 0 && this.id > 0) {
          clearInterval(this.id);
          this.id = 0;
          return;
        }
        if (val.length > 0 && this.id === 0) {
          this.id = setInterval(() => {
            this.notifications.shift();
          }, 3000);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: absolute;
  pointer-events: none;
  top: $topbar-height;
  z-index: 200;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  & > div {
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #fff;

    padding: 10px 24px;
    &.error, &.warn, &.success {
      padding-left: 12px;
      &:before {
        display: inline-block;
        margin-right: 10px;
      }
    }
    &.error {
      background-color: #ffe6e6;
      color: #c10f0f;
      &:before {
        content: '\274C';
      }
    }
    &.warn {
      background-color: #fff7d3;
      color: #a38f0a;
      &:before {
        content: '\2757';
      }
    }
    &.success {
      background-color: #ecffec;
      color: green;
      &:before {
        content: '\2705';
      }
    }
  }
}
</style>
