// TODO: logger level to be implemented (or simply turn it off for production)
// TODO: network logging for user problem solving
const randomColor = () => '#' + Math.floor(Math.random() * 2 ** 24).toString(16).padStart(6, '0');

/**
 * Usage:
 * import logger from '<...>logger';
 * logger.init('someContextString');
 * logger.info('someContextString', 'colorMessage', ...restArgs);
 * logger.group('groupName');
 * logger.info('someContextString', 'nestedColorMessage', ...restArgs)
 * logger.group('nestedGroupName');
 * <...>
 * logger.groupEnd();
 * logger.groupEnd();
 */
class Logger {
  contexts = {};

  constructor(context) {
    this.context = context;
  }

  getContextOptions(context) {
    if (this.contexts[context])
      return this.contexts[context];
    return false;
  }

  addContext(context, options) {
    this.contexts[context] = options;
  }

  getDefaultOptions() {
    return {
      color: randomColor(),
    };
  }

  init(context, options) {
    if (this.getContextOptions() === false) {
      if (!options)
        options = this.getDefaultOptions();
      this.addContext(context, options);
    }
  }

  print(f, ...args) {
    const [context, ...rest] = args;
    const options = this.getContextOptions(context);
    if (options) {
      const [message, ...more] = rest;
      f(`%c${message}`, `color: ${options.color}`, ...more);
    } else
      f(...args);
  }

  log(...args) {
    this.print(console.log, ...args);
  }

  info(...args) {
    this.print(console.info, ...args);
  }

  group(name) {
    console.group(name);
  }

  groupEnd() {
    console.groupEnd();
  }
}

const logger = new Logger();

export default logger;