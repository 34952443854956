<template>
  <div class="home">
    <header>
      <a class="menu-button svg" @click="$bus.emit('sidemenu:toggle')"></a>
      <div class="logo"><span class="red">А</span>бхаз <span class="red">А</span>вто</div>
    </header>
    <div class="main-nav grid-container svg">
      <router-link
        :to="{
          name: 'post_create',
        }"
        class="nav-item grid-item add"
      >
        Дать объявление
      </router-link>
      <router-link
        :to="{
          name: 'category',
          params: { category_id: 188 },
        }"
        class="nav-item grid-item auto"
      >
        Авто
      </router-link>
      <router-link
        :to="{
          name: 'category',
          params: { category_id: 1 },
        }"
        class="nav-item grid-item all"
      >
        Барахолка
      </router-link>
      <router-link
        :to="{
          name: 'category',
          params: { category_id: 9 },
        }"
        class="nav-item grid-item estate"
      >
        Недвижимость
      </router-link>
      <router-link :to="{ name: 'news_list' }" class="nav-item grid-item news"> Новости </router-link>
      <router-link
        :to="{
          name: 'category',
          params: { category_id: 82 },
        }"
        class="nav-item grid-item hotels"
      >
        Суточно-Тут
      </router-link>
    </div>
  </div>
</template>

<script>
// import logger from "@/api/logger";

export default {
  data() {
    return {
      height: 100, // Temporary TODO: remove
    };
  },
  mounted() {
    this.$bus.emit('topbar:hide');
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.home {
  background-image: url(./../assets/images/bg.png);
  background-repeat: no-repeat;
  -moz-background-size: 100%;
  -webkit-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  background-color: #e7e7e7;
  header {
    background-image: url(./../assets/images/topimage.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 130px;

    .menu-button {
      position: absolute;
      background: url(./../assets/images/burgermenu.png) no-repeat left top;
      background-size: 100%;
      width: 32px;
      height: 32px;
      margin: 14px;
      cursor: pointer;
      &.svg {
        background: url(./../assets/images/icons/burgermenu.svg) no-repeat left top;
        background-size: contain;
      }
    }

    .logo {
      position: absolute;
      font: 20px $base-family;
      font-weight: 500;
      margin: 18px 58px;
    }
  }
}

.main-nav {
  a {
    text-decoration: none;
    text-align: center;
    color: #000;
    font: 18px $base-family;
    padding-top: 70px;
    background-position: center 10px;
    background-repeat: no-repeat;
    background-size: 50px;
  }
}
.main-nav.svg {
  a {
    &.add {
      background-image: url(./../assets/images/icons/main/add.svg);
    }

    &.auto {
      background-image: url(./../assets/images/icons/main/car.svg);
    }

    &.all {
      background-image: url(./../assets/images/icons/main/all.svg);
    }

    &.estate {
      background-image: url(./../assets/images/icons/main/estates.svg);
    }

    &.news {
      background-image: url(./../assets/images/icons/main/news.svg);
    }

    &.comments {
      background-image: url(./../assets/images/icons/main/comments.svg);
    }

    &.hotels {
      background-image: url(./../assets/images/icons/main/hotels.svg);
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  height: calc(100vh - 180px);
}

@media (orientation: landscape) {
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 64px;
    background-image: url(./../assets/images/bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #e7e7e7;
  }
}

.grid-item {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}
</style>
