import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/views/Main.vue';
import store from '@/store';

import bus from '../bus';

// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters.isAuthenticated) {
//     next();
//     return;
//   }
//   next('/');
// };

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  store.state.from = to;
  next({ name: 'auth' });
};

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/subscribes',
    name: 'subscribes',
    meta: { scrollable: true },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Subscribes.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/favorites',
    name: 'favorites',
    meta: { scrollable: true },
    component: () =>
      import(/* webpackChunkName: "favorites" */ '../views/Favorites.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/settings',
    name: 'settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/category/:category_id',
    name: 'category',
    props: true,
    meta: { scrollable: true },
    component: () =>
      import(/* webpackChunkName: "category" */ '../views/Category.vue'),
  },
  {
    path: '/category/:category_id/search',
    name: 'search',
    props: true,
    meta: { scrollable: true },
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/Search.vue'),
  },
  {
    path: '/post/create', // ? mb /posts/create
    name: 'post_create',
    component: () =>
      import(/* webpackChunkName: "post" */ '../views/PostCreate.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/post/:post_id',
    name: 'post',
    component: () => import(/* webpackChunkName: "post" */ '../views/Post.vue'),
  },
  {
    path: '/post/:post_id/edit',
    name: 'post_edit',
    component: () => import(/* webpackChunkName: "post_edit" */ '../views/PostEdit.vue'),
  },
  {
    path: '/post/:post_id/pay',
    name: 'post_pay',
    props: true,
    meta: { scrollable: true },
    component: () => import(/* webpackChunkName: "post_pay" */ '../views/Pay.vue'),
  },
  {
    path: '/news',
    name: 'news_list',
    meta: { scrollable: true },
    component: () =>
      import(/* webpackChunkName: "news_list" */ '../views/NewsList.vue'),
  },
  {
    path: '/news/:news_id',
    name: 'news',
    props: true,
    component: () =>
      import(/* webpackChunkName: "news" */ '../views/NewsItem.vue'),
  },
  {
    path: '/last_comments',
    name: 'last_comments',
    meta: { scrollable: true },
    component: () =>
      import(
        /* webpackChunkName: "last_comments" */ '../views/LastComments.vue'
      ),
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "dialogs" */ '../views/Feedback.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/dialogs',
    name: 'dialogs',
    meta: { scrollable: true },
    component: () => import(/* webpackChunkName: "dialogs" */ '../views/Dialogs.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/dialog/:user_id',
    name: 'dialog',
    props: true,
    component: () => import(/* webpackChunkName: "dialog" */ '../views/Dialog.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/user_info/:user_id',
    name: 'user_info',
    props: true,
    component: () => import(/* webpackChunkName: "user_info" */ '../views/UserInfo.vue'),
  },
];

const history = createWebHistory(process.env.BASE_URL);

const router = createRouter({
  history,
  routes,
});

router.history = history;

router.beforeEach(() => {
  bus.emit('sidemenu:hide');
});

export default router;
