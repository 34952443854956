<template>
  <div>
    <div
      class="menu"
      :class="{
        'menu-active': visible === true,
      }"
    >
      <div class="menu-text"><span class="red">А</span>бхаз <span class="red">А</span>вто</div>
      <span class="menu-display-name" v-if="displayName">{{ displayName }}</span>
      <ul class="menu-list-items">
        <li class="menu-item add">
          <router-link :to="{ name: 'post_create' }">Добавить объявление</router-link>
        </li>

        <li class="menu-item news">
          <router-link :to="{ name: 'news_list' }">Новости</router-link>
        </li>
        <li class="menu-item comments">
          <router-link tag="li" :to="{ name: 'last_comments' }" class="menu-item comments">Комментарии</router-link>
        </li>

        <li v-for="category in categories" :key="category.id" :class="['menu-item', category.class]">
          <router-link
            :to="{
              name: 'category',
              params: { category_id: category.id },
            }"
            >{{ category.title
            }}<span class="count" v-show="newPostsCount(category.id)">{{
              newPostsCount(category.id)
            }}</span></router-link
          >
        </li>
      </ul>
    </div>
    <div :class="{ fade: true, 'fade-in': visible, 'fade-out': !visible }" @click="$bus.emit('sidemenu:hide')"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useCategoriesCache } from '@/store/cache';

// TODO: переместить в стор, т.к. состояние приложения
const categories = [
  { id: 188, title: 'Авто', class: 'auto' },
  { id: 1, title: 'Барахолка', class: 'all' },
  { id: 82, title: 'Суточно-Тут', class: 'hotel' },
  { id: 9, title: 'Недвижимость', class: 'estate' },
  { id: 190, title: 'Автозапчасти', class: 'autoparts' },
  // News
  // Comments
  { id: 189, title: 'Авто под заказ', class: 'commercial' },
  { id: 8, title: 'Работа', class: 'work' },
  { id: 4, title: 'Услуги', class: 'service' },
  { id: 12, title: 'Одежда и обувь', class: 'wear' },
  { id: 15, title: 'Электроника', class: 'electro' },
  { id: 7, title: 'Шины', class: 'wheels' },
  { id: 5, title: 'Диски', class: 'disks' },
  { id: 14, title: 'Гаражи', class: 'garage' },
  { id: 6, title: 'Животные', class: 'animal' },
  { id: 3, title: 'Оборудование', class: 'equipment' },
  { id: 11, title: 'Мебель', class: 'furniture' },
  { id: 2, title: 'Компьютеры', class: 'pc' },
  { id: 16, title: 'Все для дома', class: 'home' },
  { id: 13, title: 'Хобби', class: 'hobby' },
  { id: 10, title: 'Разное', class: 'other' },
];

export default {
  data() {
    return {
      visible: false,
      categories,
    };
  },
  computed: {
    ...mapState('profile', {
      displayName: (state) => state.displayName,
    }),
    cache() {
      return useCategoriesCache().categories;
    }
  },
  mounted() {
    this.$bus.on('sidemenu:show', () => (this.visible = true));
    this.$bus.on('sidemenu:hide', () => (this.visible = false));
    this.$bus.on('sidemenu:toggle', () => (this.visible = !this.visible));
  },
  methods: {
    newPostsCount(categoryId) {
      const count = this.cache[categoryId].newPostsCount;
      if (count > 999)
        return '999+';
      return count;
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  margin-left: -290px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  z-index: 1001;
  position: fixed;
  width: 290px;
  height: 100%;
  top: 0;
  background-color: #fff;
  //background: #fff url(./../assets/images/topimage.png) center -20px no-repeat local;
  overflow: auto;

  .menu-text {
    position: relative;
    margin: 35px 20px;
    font: 28px Arial, sans-serif;
    font-weight: bold;
  }

  ul.menu-list-items {
    position: relative;
    margin-top: 50px;
    padding: 0;

    li.menu-item {
      // height: 30px;
      // margin-left: -30px;
      list-style-type: none;
      background-repeat: no-repeat;
      background-size: 25px;
      // padding-left: 35px;
      background-position: 10px center;
      // padding-top: 10px;

      a {
        display: block;

        padding-left: 45px;

        font: 16px/40px $base-family;
        color: black;
        text-decoration: none;
      }

      span.count {
        float: right;
        margin: 6px 10px;

        border-radius: 5px;
        padding: 5px 10px;

        font: 14px $base-family;
        color: #fff;
        background-color: #cf0000;
      }

      &.add {
        background-image: url(./../assets/images/categories/add.png);
      }

      &.auto {
        background-image: url(./../assets/images/categories/car.png);
      }

      &.all {
        background-image: url(./../assets/images/categories/all.png);
      }

      &.estate {
        background-image: url(./../assets/images/categories/house.png);
      }

      &.news {
        background-image: url(./../assets/images/categories/news.png);
      }

      &.comments {
        background-image: url(./../assets/images/categories/comments.png);
      }

      &.autoparts {
        background-image: url(./../assets/images/categories/parts.png);
      }

      &.commercial {
        background-image: url(./../assets/images/categories/ordercar.png);
      }

      &.work {
        background-image: url(./../assets/images/categories/work.png);
      }

      &.hotel {
        background-image: url(./../assets/images/categories/hotel.png);
      }

      &.service {
        background-image: url(./../assets/images/categories/service.png);
      }

      &.wear {
        background-image: url(./../assets/images/categories/wear.png);
      }

      &.electro {
        background-image: url(./../assets/images/categories/electro.png);
      }

      &.wheels {
        background-image: url(./../assets/images/categories/wheels.png);
      }

      &.disks {
        background-image: url(./../assets/images/categories/disks.png);
      }

      &.garage {
        background-image: url(./../assets/images/categories/garazh.png);
      }

      &.animal {
        background-image: url(./../assets/images/categories/animal.png);
      }

      &.equipment {
        background-image: url(./../assets/images/categories/equipment.png);
      }

      &.furniture {
        background-image: url(./../assets/images/categories/furniture.png);
      }

      &.pc {
        background-image: url(./../assets/images/categories/pc.png);
      }

      &.home {
        background-image: url(./../assets/images/categories/utvar.png);
      }

      &.hobby {
        background-image: url(./../assets/images/categories/hobby.png);
      }

      &.other {
        background-image: url(./../assets/images/categories/stuff.png);
      }
    }
  }
}

.menu-active {
  margin-left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.menu-inactive {
  margin-left: -290px;
}

.menu-display-name {
  font-family: $base-family;
  font-size: 14px;
  position: absolute;
  margin: -20px 0 0 20px;
}

.fade {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  height: 100%;
  background-color: #000;

  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
}

.fade-in {
  opacity: 0.5;
}
</style>
