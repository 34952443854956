<template>
  <nav v-show="visible" class="svg">
    <router-link
      :to="{ name: 'subscribes' }"
      class="nav-item subscribes"
      active-class="active"
      exact
      >Подписки
    </router-link>
    <router-link
      :to="{ name: 'profile' }"
      class="nav-item profile"
      active-class="active"
      exact
      >Профиль
    </router-link>
    <router-link
      :to="{ name: 'main' }"
      class="nav-item home"
      active-class="active"
      exact
      >Главная
    </router-link>
    <router-link
      :to="{ name: 'favorites' }"
      class="nav-item favorites"
      active-class="active"
      exact
      >Избранное
    </router-link>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  emits: ['toggle'],
  data() {
    return {
      visible: true,
    };
  },
  mounted() {
    this.$bus.on('navbar:show', () => (this.visible = true));
    this.$bus.on('navbar:hide', () => (this.visible = false));
  },
  watch: {
    visible(value) {
      this.$emit('toggle', value);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  border-top: 2px solid #dcdcdc;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $navbar-height;
  text-align: center;
  z-index: 70;
  a.nav-item {
    text-decoration: none;
    color: #000;

    display: inline-block;
    font: 12px $base-family;
    width: 24%;
    height: 50px;

    padding-top: 35px;
    box-sizing: border-box;

    text-align: center;

    background-repeat: no-repeat;
    background-position: center 0px;
    background-size: 32px;
    &.subscribes {
      background-image: url(./../assets/images/nav_subscribes.png);
      &.active {
        background-image: url(./../assets/images/nav_subscribes_active.png);
        color: #ce0202;
      }
    }
    &.profile {
      background-image: url(./../assets/images/nav_profile.png);
      &.active {
        background-image: url(./../assets/images/nav_profile_active.png);
        color: #ce0202;
      }
    }
    &.home {
      background-image: url(./../assets/images/nav_home.png);
      &.active {
        background-image: url(./../assets/images/nav_home_active.png);
        color: #ce0202;
      }
    }
    &.favorites {
      background-image: url(./../assets/images/nav_favorites.png);
      &.active {
        background-image: url(./../assets/images/nav_favorites_active.png);
        color: #ce0202;
      }
    }
  }
}

nav.svg {
  a.nav-item {
    &.active {
      filter: brightness(0) saturate(100%) invert(9%) sepia(95%) saturate(6766%) hue-rotate(9deg) brightness(99%) contrast(111%);
      // #ce0202 filter:
      //filter: brightness(0) saturate(100%) invert(9%) sepia(95%) saturate(6766%) hue-rotate(9deg) brightness(89%) contrast(111%);
    }
    &.subscribes {
      background-image: url(./../assets/images/icons/navbar/subscriptions.svg);
    }
    &.profile {
      background-image: url(./../assets/images/icons/navbar/profile.svg);
    }
    &.home {
      background-image: url(./../assets/images/icons/navbar/main.svg);
    }
    &.favorites {
      background-image: url(./../assets/images/icons/navbar/favorites.svg);
    }
  }
}
</style>
