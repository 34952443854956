<template>
  <div v-if="visible" class="top-bar">
    <a
      v-if="back"
      class="action back-button"
      @click.prevent="onBackButtonClick"
    ></a>
    <a
      v-if="menu"
      class="action menu-button svg"
      @click.prevent="$bus.emit('sidemenu:show')"
    ></a>

    <div class="common-title">
      <span class="common-title-text">{{ title }}</span>
      <span class="common-title-count">{{ subtitle }}</span>
    </div>
    <a
      v-if="signout"
      class="right-menu-button signout"
      @click.prevent="signOut"
    ></a>
    <router-link
      v-if="settings"
      class="right-menu-button settings"
      :to="{ name: 'settings' }"
    ></router-link>
    <a v-if="filter" class="right-menu-button filter" @click.prevent=""></a>
    <a v-if="fav" class="right-menu-button fav" :class="fav.state ? 'filled' : ''" @click.prevent="fav.handler"></a>
    <a v-if="share" @click="share" class="right-menu-button share"></a>
    <a v-if="add" @click="add" class="right-menu-button add"></a>
    <a v-if="close" @click="close" class="right-menu-button close"></a>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  emits: ['toggle'],
  data() {
    return {
      visible: false, // важно, чтобы было = App.indented // ? вероятно стоит задать связь
      title: '',
      subtitle: '',

      // buttons visibility
      add: false,
      back: false,
      close: false,
      fav: false,
      filter: false,
      menu: false,
      signout: false,
      settings: false,
      share: false,
    };
  },
  watch: {
    visible(value) {
      this.$emit('toggle', value);
    },
  },
  mounted() {
    // Vuex архитектурно убог с такими компонентами как топбар, навбар, меню, используем event bus
    // Топбар не должен анмаунтится и повторно маунтится, поэтому мы не удаляем обработчики
    // в unmounted()
    this.$bus.on('topbar:setup', this.onSetup);
    this.$bus.on('topbar:show', () => (this.visible = true));
    this.$bus.on('topbar:hide', () => (this.visible = false));
  },
  methods: {
    async signOut() {
      // TODO: probably should be moved to store
      await this.$store.dispatch('auth/signOut');
      this.$router.push({ name: 'main' });
    },
    onSetup({
      title = '',
      subtitle = '',
      add = false,
      back = false,
      close = false,
      fav = false,
      filter = false,
      menu = false,
      signout = false,
      settings = false,
      share = false,
    }) {
      // console.log(back, menu, settings, share);
      // Если настраиваем топбар, значит хотим его видеть (пока это верно)
      this.visible = true;
      Object.assign(this, {
        title,
        subtitle,
        add,
        back,
        close,
        fav,
        filter,
        menu,
        signout,
        settings,
        share,
      });
    },
    onBackButtonClick() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  //z-index: 1000;
  height: $topbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ce0202;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  .action {
    position: absolute;
    background-size: 100%;
    width: 32px;
    height: 32px;
    margin-top: 14px;
    margin-left: 14px;
    cursor: pointer;

    &.menu-button {
      background-image: url(./../assets/images/burgermenu_white.png);
      &.menu-button.svg {
        background-image: url(./../assets/images/icons/burgermenu.svg);
        background-size: contain;
        filter: brightness(0) saturate(100%) invert(1);
      }
    }
    &.back-button {
      background-image: url(./../assets/images/back.png);
    }
  }
  .right-menu-button {
    background-size: 100%;
    float: right;
    width: 32px;
    height: 32px;
    margin-right: 14px;
    margin-top: 14px;

    cursor: pointer;

    &.close {
      background-image: url(./../assets/images/close.png);
    }

    &.fav {
      &.filled {
        background-image: url(./../assets/images/icons/fav_filled.svg);
      }
      /* background-image: url(./../assets/images/like.png); */
      background-image: url(./../assets/images/icons/fav.svg);
      background-size: 28px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.share {
      background-image: url(./../assets/images/share.png);
    }

    &.settings {
      background-image: url(./../assets/images/settings.png);
    }

    &.signout {
      background-image: url(./../assets/images/sign_out.png);
    }

    &.filter {
      background-image: url(./../assets/images/filter.png);
    }

    &.grid-switch {
      background-image: url(./../assets/images/list.png);
    }

    &.add {
      background-image: url(./../assets/images/add.png);
    }

    &.accept {
      background-image: url(./../assets/images/tick.png);
    }
  }

  .common-title {
    user-select: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 70px;

    span.common-title-text {
      display: block;
      font-weight: 500;
      font-family: $base-family;
      @media (max-width: 680px) {
        font-size: 18px;
      }
      @media (min-width: 680px) {
        font-size: 22px;
      }
      color: #fff;
    }

    span.common-title-count {
      display: block;
      @media (max-width: 680px) {
        font: 14px $base-family;
        font-weight: bold;
      }
      @media (min-width: 680px) {
        font: 18px $base-family;
        font-weight: bold;
      }
      color: #ffffff9c;
    }
  }
}
</style>
