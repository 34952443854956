import axios from 'axios';
import { apiUrl } from '../config';

const endpoint = `${apiUrl}/profile`;

export default {
  async getInfo() {
    const response = await axios.post(`${endpoint}/info`);
    if (response.data.success) return response.data;
    return response.data.message;
  },
  async getPosts() {
    const response = await axios.post(`${endpoint}/posts`);
    if (response.data.success) return response.data.entities;
    return response.data.message;
  },
  async getComments() {
    const response = await axios.post(`${endpoint}/comments`);
    if (response.data.success) return response.data.entities;
    return response.data.message;
  },
};
