<template>
  <top-bar @toggle="setIndent"/>
  <side-menu/>
  <notifications/>
  <main :class="{ indented, nonav, scrollable }">
    <router-view v-slot="{ Component }">
      <keep-alive :include="['Category']">
        <component :is="Component" :key="removeParam($route.fullPath, 'position')"/>
      </keep-alive>
    </router-view>
  </main>
  <nav-bar @toggle="setNoNav"/>
</template>

<script>
import router from '@/router';
import SideMenu from '@/components/SideMenu.vue';
import TopBar from '@/components/TopBar.vue';
import NavBar from '@/components/NavBar.vue';
import Notifications from '@/components/Notifications';
// import touches from '@/api/desktop-touches';
import { useCategoriesCache } from '@/store/cache';

export default {
  components: { SideMenu, TopBar, NavBar, Notifications },
  data() {
    return {
      indented: false, // отступ контента при видимом топбаре (на высоту топбара)
      nonav: false,
      scrollable: false,
    };
  },
  mounted() {
    router.afterEach(to => {
      this.scrollable = !!to.meta.scrollable;
      useCategoriesCache().requestUpdate();
    });
    setInterval(useCategoriesCache().requestUpdate, 600000);
    // touches.emulate();
  },
  methods: {
    removeParam(fullPath, paramName) {
      var regex = new RegExp('([?&])' + paramName + '=[^&]*[&]?');
      return fullPath.replace(regex, '$1').replace(/[&?]$/, '');
    },
    setIndent(topbarVisible) {
      this.indented = topbarVisible;
      /*
       * Пояснение по дизайну.
       * Видимость топбара можно было определить в данных App и передавать, как prop в компонент.
       * При этом для того, чтобы соблюсти принцип черного ящика нужно передавать как v-model, т.к.
       * прятать или показывать топбар должен сам компонент (вдруг на это завязана какая-то логика и пр.).
       * НО. Делается иначе - т.к. видимость топбара это его внутренне состояние, то логично его оставить
       * в самом компоненте, а во вне компонента получать нужные данные через события этого компонента.
       *
       * В случае с топбаром это видится лучшим решением, как и использование шины событий (event bus),
       * вместо Vuex.
       *
       * В тоже время v-model активно используется для компонент-полей форм, т.к. данные принадлежат форме,
       * а не полю.
       */
    },
    setNoNav(navbarVisible) {
      this.nonav = !navbarVisible;
    },
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;

  font: 16px $base-family;
}

.scrollable {
  overflow-y: scroll;
}

// TODO! remove?
#app {
  height: 100vh;
}

.red {
  color: #cf0000;
}

main {
  position: relative;
  width: 100%;
  height: calc(100vh - #{$navbar-height});
  overflow: hidden;
  //overflow-y: scroll;
  &.indented {
    height: calc(100vh - #{$topbar-height} - #{$navbar-height});
    top: $topbar-height;
    &.nonav {
      height: calc(100vh - #{$topbar-height});
    }
  }
}
</style>
