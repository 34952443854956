import axios from 'axios';
import { apiUrl } from '../config';

const endpoint = `${apiUrl}/favorites`;

export default {
  async getList() {
    const result = await axios.get(`${endpoint}/list`);
    if (result.data.success)
      return result.data.ids;
    else
      return [];
  },
  async getEntities() {
    const result = await axios.get(`${endpoint}`);
    if (result.data.success)
      return result.data.entities;
  },
  async add(postId) {
    console.log(`Post ${postId} was added to favorites`);
    await axios.post(`${endpoint}/${postId}`);
    return true;
  },
  async remove(postId) {
    console.log(`Post ${postId} was removed from favorites`);
    await axios.delete(`${endpoint}/${postId}`);
    return true;
  },
};
